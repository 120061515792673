/* You can add global styles to this file, and also import other style files */
@import "app/shared/stylesheets/variables"
@import "app/shared/stylesheets/fonts"
@import "app/shared/stylesheets/scroll"
@import "app/shared/stylesheets/inputs"
@import "app/shared/stylesheets/container"
@import "app/shared/stylesheets/buttons"
@import "app/shared/stylesheets/datepicker"
@import "bootstrap/dist/css/bootstrap.min.css"
@import "~@ng-select/ng-select/themes/default.theme.css"  
@import "angular-calendar/css/angular-calendar.css"

body
    color: #53565A !important

.cal-month-view
    .cal-days
        border: 0 !important
        border-bottom: 1px solid #e1e1e1 !important

    .cal-day-cell
        min-height: 50% !important
        width: 14.28% !important
        flex: none !important

.cal-week-view .cal-day-headers
    padding-left: 0 !important

mwl-calendar-month-cell

    .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y
        width: 0 !important
        z-index: 2

.outer-body-scroll
    height: 71.2vh !important

    .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y
        background-color: #999
        width: 0 !important

    .ps.ps--active-y > .ps__rail-y:hover, perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking
        width: 0 !important

    perfect-scrollbar > .ps.ps--active-y > .ps__rail-y
        width: 0 !important

.cal-week-view
    .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y
        background-color: #999
        width: 6px !important

    .ps.ps--active-y > .ps__rail-y:hover, perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking
        width: 0 !important

    perfect-scrollbar > .ps.ps--active-y > .ps__rail-y
        width: 0 !important

.outer-body-scroll
    .ps-content
        height: 100% !important


        .cal-days
            height: 100% !important

            .one-week
                height: 20% !important

                .cal-cell-row
                    height: 100% !important

.inner-scroll-bar
    .ps.ps--active-y > .ps__rail-y:hover, perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking
        width: 0 !important

    .ps__thumb-y
        width: 0 !important

    .ps
        height: 100% !important

        .ps-content
            height: auto !important
            max-height: 100% !important

.cal-month-view
    .cal-cell-top
        min-height: 0 !important

.saturday
    .ps__thumb-y
        right: 0 !important

    .ps.ps--active-y > .ps__rail-y
        right: 95% !important

mwl-calendar-month-cell

    perfect-scrollbar > .ps.ps--active-y > .ps__rail-y
        width: 0 !important
        z-index: 2

mwl-calendar-month-cell

    perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover, perfect-scrollbar > .ps.ps--active-y > .ps__rail-y.ps--clicking
        width: 10px !important
        z-index: 2

.form-error-message
    color: red
    font-size: smaller
    margin-left: 10px

.ng-select-container
    input
        padding: 0 !important

.disable_field
    .ng-select-container
        background-color: lightgray !important
        pointer-events: none !important

.errorMessage
    font-size: 14px
    color: red
    margin-bottom: 10px

my-date-picker
    .headelabelbtn
        color: black !important

.mydp .headerbtn, .mydp .headerlabelbtn, .mydp .yearchangebtn
    color: black !important

.action-alert-box
    .modal-content
        width: 21% !important

.tactic-id-box
    ng-select
        .ng-value-label
            font-size: 14px !important

.details-row-3
    .mydp
        width: 14.3rem !important

.pointer-cursor
    cursor: pointer

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder
    top: unset !important
    font-size: 14px !important


//Home Container
.homepage
    padding-top: 20px
    background-color: #f2f2f2
    min-height: calc(100vh - 95px)

    .outerframe
        display: flex
        flex-direction: column
        margin: 25px
        padding: 20px
        background: white
        border: 1px solid #D0DDE6


.input-group
    display: flex
    flex-direction: column
    background: white



.frametitle
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 25px

    .left
        header
            font-size: 18px
            color: #53565A
            font-weight: 400


    .right
        display: flex
        justify-content: flex-end
        font-size: 14px

        .marker
            border-left: 3px solid #DFB102
            margin-right: 5px
            height: 14px


.frameheader
    display: flex
    flex-direction: column
    margin-bottom: 15px
    color: #53565A

    .main
        font-size: 13px
        font-weight: 700
        margin-bottom: 5px

    .desc
        font-size: 14px
        font-weight: 400


.frameform
    display: flex
    flex-wrap: wrap

    .frameform-item
        display: flex
        flex-direction: column
        width: 30%
        margin-right: 3%
        margin-bottom: 20px

        .main
            font-size: 13px
            font-weight: 700
            margin-bottom: 5px


        .input
            height: 36px
            border-left: 3px solid white

            i
                border-top: 1px solid #CFDDE6
                border-bottom: 1px solid #CFDDE6
                height: 37px
                display: flex
                align-items: center
                padding-left: 10px
                padding-right: 5px

            input
                border: 1px solid #CFDDE6
                border-top-left-radius: 0
                border-bottom-left-radius: 0
                outline: none
                font-size: 14px
                padding: 9px 15px
                width: 100%


            input::placeholder
                color: #98A4AE
                font-size: 14px

            textarea
                height: 100%
                font-size: 14px

            ng-select
                width: 100%

            .ng-select.custom ::ng-deep .ng-select-container .ng-value-container .ng-placeholder
                font-size: 14px
                color: #98A4AE

            .ng-select.custom ::ng-deep .ng-select-container .ng-value-container .ng-value .ng-value-label
                padding: 2px 0
                line-height: 26px !important


            .ng-select.custom ::ng-deep .ng-select-container .ng-value-container .ng-value
                font-size: 14px

                input
                    border: none

            .ng-select.custom ::ng-deep .ng-select-container
                border: 1px solid #CFDDE6
                font-weight: 400 !important
                border-radius: 0


            .mydp
                padding: 0
                border: 1px solid #CFDDE6
                border-radius: 0

                input
                    border: none

                .selector
                    left: 90%



        .input-border
            border-left: 3px solid #DFB102

            input, .ng-select.custom ::ng-deep .ng-select-container, .mydp
                border-left: 1px solid white

        .input-large
            height: 100px


.framebuttons
    display: flex
    margin-top: 70px

    p
        margin: 0

    .cancel
        display: flex
        width: 100px
        height: 34px
        align-items: center
        justify-content: center
        margin-left: 10px
        color: $modal-top-strip
        border: 1px solid $modal-top-strip
        border-radius: 5px
        cursor: pointer

        &:hover
            border-color: $hover-button-border
            color: $request-lov-text-color


    button
        margin-left: 15px


.frameerror
    display: flex
    align-items: center
    justify-content: center
    height: 100%

    img
        width: 35px
        height: 35px




.action-btn-separator
    border-left: 0.1em solid #e4e4e4

.edit-svg-icon
    width: 23.44px !important
    height: 23.36px !important

.view-svg-icon
    width: 20.48px !important
    height: 23.88px !important

.add-svg-icon
    width: 15.8px !important
    height: 15.8px !important

.cross-svg-icon
    width: 17.28px !important
    height: 17.28px !important

.tactic-name
   .tooltip
       margin-left: 40px !important
       z-index: 9999999999 !important

.disable-item
    pointer-events: none
    opacity: 0.4
.charLimitMsg
    font-size: 14px
    color: red
    margin: 10px 0 0 0
.flexi-field-box
    label
        border-left: 0!important
        height: auto !important
    ng-select
        border-left: 3px solid #DFB102

.ng-select.ng-select-multiple .ng-select-container .ng-value-container
    flex-wrap: nowrap !important

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child 
    padding-left: 9px !important

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup
    font-weight: unset

.frameform
    .frameform-item
        .input 
            .ng-select-disabled .ng-select-container
                color: #555 !important
                background-color:  #eee
                opacity: 0.65