/* Scroll Bars */
/* width */
::-webkit-scrollbar
    width: 6px


/* Track */
::-webkit-scrollbar-track
    background: #f1f1f1


/* Handle */
::-webkit-scrollbar-thumb
    background: #888
    border-radius: 4px


/* Handle on hover */
::-webkit-scrollbar-thumb:hover
    background: #555







