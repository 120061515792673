/* Angular Theming */
$pri-font: 'Roboto'
$sec-font: Arial

/* Containers */
$page-primary-background: #ffffff
$page-secondary-background: #f2f2f2
$page-lines: #d0dde6

/* Text */
$text-heading-dark: #53565a
$text-heading-teal: #327f97
$text-heading-light-teal: #4c94af
$text-heading-orange: #ed8b00


/* Text */
$header-height: 55px
$taskbar-height: 47px
$header-border-height: 4px

$white: #ffffff
$orange-color: #ED8B00
$font-color-signin: #83878c
$dropdown-background-color: #F9F9F9
$dropdown-hover-background-color: #F1F1F1
$font-color: #53565A
$notification-background-color: #bc062d
$box-shadow: #e4e4e4
$gray-background: #F2F2F2
$table-header: #e5eaef
$modal-top-strip: #327f97
$opaque-color: rgba(0, 0, 0, 0.11)

//Link Hover
$link-hover-color: #4C94AF
$request-lov-text-color: #138DBF

//Normal Button
$button-color: #498493
$gradient-bottom: #48778A
$gradient-top: #498493
$campaign-background: #F4F6F9

//Font
$primary-font: 'Roboto'
$fallback-font: sans-serif

//Tiles Data
$tile-3: #6e2b62
$active-color: #409F52
$draft-color: #999999
$tile-4: #00629b
$border-color: #D0DDE6
$input-box-border: #98a4ae

//Buttons
$button-color: #498493
$hover-button-background: #499BBC
$hover-gradient-bottom: #387E9A
$hover-button-border: #0099c9

$screen-width: 100%

$orange: darkorange
$blue: steelblue
$purple: purple