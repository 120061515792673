@import "variables"

div, h1, h2, h3, h4, h5, h6, header, section, p, a, label
    margin: 0
    padding: 0
    line-height: 1.2
    font-family: $pri-font, $sec-font

input:focus::-webkit-input-placeholder
    color: transparent
input:focus:-moz-placeholder
    color:  transparent /* FF 4-18 */
input:focus::-moz-placeholder
    color: transparent /* FF 19+ */
input:focus:-ms-input-placeholder
    color: transparent /* IE 10+ */

