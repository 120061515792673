@import "variables"


input,textarea
    border: 1px solid $page-lines
    padding: 10px
    font-size: 14px
    cursor: text

.input-container
    height: 36px
    border: 1px solid $page-lines
    cursor: text


    input,textarea
        border: none
        outline: none
        height: 34px

    .search-icon
        height: 37px
        padding: 0 10px
        cursor: pointer


        img
            width: 17px !important
            height: 17px !important






