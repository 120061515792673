button.plain
    background: white
    border: none
    color: $button-color

button.outline
    background: white
    border: 1px solid $button-color
    color: $button-color

button, .btn
    display: flex
    align-items: center
    justify-content: center
    min-height: 34px
    font-family: Roboto, sans-serif
    transition: 0.3s
    border: 1px solid #327F97
    border-radius: 3px
    font-size: 14px
    padding: 8px 15px 8px 15px
    text-decoration: none
    font-weight: 400
    color: #FFFFFF
    background-color: #498493
    background-image: -webkit-gradient(linear, left top, left bottom, from(#498493), to(#48778A))
    background-image: -webkit-linear-gradient(top, #498493, #48778A)
    background-image: -moz-linear-gradient(top, #498493, #48778A)
    background-image: -ms-linear-gradient(top, #498493, #48778A)
    background-image: -o-linear-gradient(top, #498493, #48778A)
    background-image: linear-gradient(to bottom, #498493, #48778A)
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#498493, endColorstr=#48778A)
    cursor: pointer

    &:hover
        color: white
        transition: 0.3s
        border: 1px solid #0099c9
        background-color: #499BBC
        background-image: -webkit-gradient(linear, left top, left bottom, from(#499BBC), to(#387E9A))
        background-image: -webkit-linear-gradient(top, #499BBC, #387E9A)
        background-image: -moz-linear-gradient(top, #499BBC, #387E9A)
        background-image: -ms-linear-gradient(top, #499BBC, #387E9A)
        background-image: -o-linear-gradient(top, #499BBC, #387E9A)
        background-image: linear-gradient(to bottom, #499BBC, #387E9A)
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#499BBC, endColorstr=#387E9A)


    &:focus
        outline: none

    &:disabled
        opacity: 0.5
        cursor: default

        &:hover
            background-color: #498493
            background-image: none
            filter: none


    header
        margin: 0 7px

    img
        width: 18px
        height: 18px
