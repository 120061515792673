.mydp
    border-radius: 2px
    font-family: $pri-font, $sec-font

    .selection
        padding-left: 10px !important


    .selbtngroup
        height: 32px !important


    .selectiongroup
        input
            font-size: 14px !important
            height: 32px !important


    .btnpicker
        //width: 24px !important
        //padding-right: 13px !important
        &:hover
            background: none !important


        &:focus
            background: none !important


    .btnclear
        display: none


    .icon-mydpcalendar, .icon-mydpremove
        font-size: 14px !important


    .btnclear, .btndecrease, .btnincrease, .btnpicker, .headerbtn, .headermonthtxt, .headertodaybtn, .headeryeartxt, .yearchangebtn
        color: $modal-top-strip !important


    .headertodaybtn, .selection, .selectiongroup, .selector
        border-radius: 2px !important


    .headertodaybtn
        border: none !important


    .headerlabelbtn
        color: $font-color
